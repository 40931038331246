import { useCompanyExternalLinks } from './use-company-external-links.query';
import { useAddCompanyExternalLink } from './use-add-company-external-link.mutation';
import { useRecoilValue } from 'recoil';
import { companyIdState } from '../company.state';
import { useRemoveCompanyExternalLink } from './use-remove-company-external-link.mutation';
import { ExternalLinksWidget } from '@/components/external-links-widget/external-links-widget';
import { WIDGET_ROW_1_HEIGHT } from '../utils/constants';

export const CompanyExternalLinksWidget = ({
  className,
}: {
  className?: string;
}) => {
  const companyId = useRecoilValue(companyIdState);
  const { data: externalLinks, isLoading } = useCompanyExternalLinks();
  const { mutateAsync: addExternalLink } = useAddCompanyExternalLink();
  const { mutateAsync: removeExternalLink } = useRemoveCompanyExternalLink();

  const handleAddLink = async (link: string, title?: string | null) => {
    await addExternalLink({
      companyId: companyId ?? '',
      data: { urlValue: link, title: title ?? null },
    });
  };

  const onRemoveLink = async (id: string) =>
    await removeExternalLink({ companyId: companyId ?? '', linkId: id });

  return (
    <ExternalLinksWidget
      removeLinkHandler={onRemoveLink}
      addLinkHandler={handleAddLink}
      linksData={externalLinks ?? []}
      isLoading={isLoading}
      className={className}
      height={WIDGET_ROW_1_HEIGHT}
      emptyStateConfig={{
        direction: 'column',
        labelAlign: 'center',
        padding: '16px 30px',
      }}
    />
  );
};
