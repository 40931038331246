import { Input } from '@/ui/input/input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { externalLinkValidationSchema } from './external-link-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import type { z } from 'zod';
import { Stack } from '@/ui/line/line';
import { forwardRef, useImperativeHandle } from 'react';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Button } from '@/ui/button/button';

// Create type from the Zod schema
export type ExternalLinkFormValues = z.infer<
  typeof externalLinkValidationSchema
>;

export interface ExternalLinkFormProps {
  isLoading?: boolean;
  defaultValues?: Partial<ExternalLinkFormValues>;
  onSubmit?: (data: ExternalLinkFormValues) => void;
  onCancel?: () => void;
  showActionButtons?: boolean;
}

export interface ExternalLinkFormRef {
  reset: () => void;
  submit: () => void;
}

export const ExternalLinkForm = forwardRef<
  ExternalLinkFormRef,
  ExternalLinkFormProps
>(
  (
    {
      isLoading,
      defaultValues = { title: '', url: '' },
      onSubmit,
      onCancel,
      showActionButtons = true,
    },
    ref,
  ) => {
    const { t } = useTranslation('companies', { keyPrefix: 'externalLinks' });

    const {
      register,
      handleSubmit,
      formState: { errors, isDirty },
      reset,
    } = useForm<ExternalLinkFormValues>({
      defaultValues,
      resolver: zodResolver(externalLinkValidationSchema),
      mode: 'onSubmit',
    });

    const handleFormSubmit = (data: ExternalLinkFormValues) => {
      if (onSubmit) {
        onSubmit(data);
      }
      reset();
    };

    const handleCancel = () => {
      reset();
      if (onCancel) {
        onCancel();
      }
    };

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      reset,
      submit: () => handleSubmit(handleFormSubmit)(),
    }));

    return (
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack>
          <Input
            placeholder={t`titleInputPlaceholder`}
            disabled={isLoading}
            {...register('title')}
            error={errors.title?.message}
          />
          <Input
            placeholder={t`inputPlaceholder`}
            disabled={isLoading}
            {...register('url')}
            error={errors.url?.message}
          />
          {showActionButtons && (
            <Flexbox justify="right">
              <Button
                type="button"
                onClick={handleCancel}
                disabled={isLoading}
                variant="ghost"
              >
                {t('cancel')}
              </Button>
              <Button
                type="submit"
                disabled={isLoading || !isDirty}
                variant="ghost"
              >
                {t('add')}
              </Button>
            </Flexbox>
          )}
        </Stack>
      </form>
    );
  },
);

ExternalLinkForm.displayName = 'ExternalLinkForm';
