import { z } from 'zod';

import i18n from '@/lib/i18n/i18n';

const { t } = i18n;

export const externalLinkValidationSchema = z.object({
  title: z.string().optional().nullable(),
  url: z.string().nonempty(t`default:fieldIsRequired`),
});
