import ExpandArrows from '@/assets/widgets/expand-arrows.svg';
import { WIDGET_HEADER_MARGIN } from '@/features/companies/overview/utils/constants';
import { Stack } from '@/ui/line/line';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { Header, SubHeaderBold } from '@/ui/typography/widgets';
import type { EmptyStateWidgetProps } from '@/ui/widget/empty-state-widget';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';
import { styled } from 'goober';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkListItem } from './external-link-list-item';
import type {
  ExternalLinkFormRef,
  ExternalLinkFormValues,
} from './external-link-form';
import { ExternalLinkForm } from './external-link-form';
import { Button } from '@/ui/button/button';
import { FlexContainer } from '@/ui/flexbox/flexbox';
import OutsideClickHandler from 'react-outside-click-handler';

type ExternalLink = {
  id: string;
  urlValue: string;
  title: string | null;
};

interface ExternalLinksWidgetProps {
  className?: string;
  height: number;
  listMaxHeight?: number;
  addLinkHandler: (url: string, title?: string | null) => Promise<void>;
  removeLinkHandler: (linkId: string) => Promise<ExternalLink>;
  emptyStateConfig?: Partial<EmptyStateWidgetProps>;
  smallHeader?: boolean;
  linksData: ExternalLink[];
  isLoading: boolean;
}

const HeaderComponent = ({
  children,
  isSmall,
}: {
  children: React.ReactNode;
  isSmall: boolean;
}) => {
  if (isSmall) {
    return <SubHeaderContainer>{children}</SubHeaderContainer>;
  }

  return <HeaderContainer>{children}</HeaderContainer>;
};

export const ExternalLinksWidget = ({
  linksData,
  isLoading,
  addLinkHandler,
  removeLinkHandler,
  emptyStateConfig,
  className,
  height,
  listMaxHeight,
  smallHeader = false,
}: ExternalLinksWidgetProps) => {
  const { t } = useTranslation('companies', { keyPrefix: 'externalLinks' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef<ExternalLinkFormRef>(null);

  const onRemoveLink = async (id: string) => {
    await removeLinkHandler(id);
    if (linksData.length === 1) {
      setIsEditMode(false);
    }
  };

  const handleFormSubmit = async (data: ExternalLinkFormValues) => {
    if (!data.url) return;
    await addLinkHandler(data.url, data.title);
    setShowForm(false);
  };

  const handleAddNewLink = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    formRef.current?.reset();
    if (linksData.length < 1) {
      setIsEditMode(false);
    }
  };

  if (!isEditMode && !Boolean(linksData.length) && !isLoading) {
    return (
      <EmptyStateWidget
        height={height}
        onEditClick={() => {
          setIsEditMode(true);
          setShowForm(true);
        }}
        label={t`emptyStateLabel`}
        image={ExpandArrows}
        linkText={t`emptyStateLinkText`}
        gap={21}
        labelGap="20px"
        className={className}
        {...emptyStateConfig}
      />
    );
  }

  return (
    <div className={className}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsEditMode(false);
          handleCancel();
        }}
      >
        <Widget
          height={height}
          showEditButton={!isEditMode}
          onEditClick={() => setIsEditMode(true)}
          header={
            <HeaderComponent isSmall={smallHeader}>
              {isLoading ? '' : t('header')}
            </HeaderComponent>
          }
        >
          {isLoading ? (
            <SkeletonText />
          ) : (
            <FlexContainer
              direction="column"
              height="100%"
              justify="space-between"
            >
              <Stack fullHeight>
                {isEditMode && showForm && (
                  <ExternalLinkForm
                    ref={formRef}
                    isLoading={isLoading}
                    onSubmit={handleFormSubmit}
                    onCancel={handleCancel}
                    showActionButtons={true}
                  />
                )}

                <List
                  gap={isEditMode ? '15px' : '24px'}
                  isListHigher={!isEditMode}
                  maxHeight={listMaxHeight}
                  fullHeight
                >
                  {linksData.map(link => (
                    <ExternalLinkListItem
                      key={link.id}
                      externalLink={link}
                      onRemoveClick={onRemoveLink}
                      isRemoveButtonVisible={isEditMode}
                    />
                  ))}
                </List>
              </Stack>

              {isEditMode && !showForm && (
                <Stack margin="24px 0 0">
                  <Button variant="ghost" onClick={handleAddNewLink}>
                    {t('add')}
                  </Button>
                </Stack>
              )}
            </FlexContainer>
          )}
        </Widget>
      </OutsideClickHandler>
    </div>
  );
};

const HeaderContainer = styled(Header)`
  margin: ${WIDGET_HEADER_MARGIN};
`;

const SubHeaderContainer = styled(SubHeaderBold)`
  margin: ${WIDGET_HEADER_MARGIN};
`;

const List = styled(Stack)<{ isListHigher: boolean; maxHeight?: number }>`
  padding-top: 8px;
  max-height: ${({ isListHigher, maxHeight }) =>
    isListHigher
      ? maxHeight
        ? `${maxHeight}px`
        : '210px'
      : maxHeight
      ? `${maxHeight - 60}px`
      : '150px'};
  overflow-y: auto;
  ${({ theme }) => theme.mixins.scrollbar};
`;
